import { capitalizeInitialChar } from "../../../../../../../../../../common/util/String/capitalizeInitialChar";
import {
    getKanjiTestSaveKey,
    sFetchAllKanjiTest,
} from "../../../../../progressManager/kanji";
import { Quest } from "../types";

export async function getKanjiQuests(): Promise<Quest[]> {
    const allKanjiTest = await sFetchAllKanjiTest();
    return [...allKanjiTest].reverse().map(g => {
        return {
            questKey: getKanjiTestQuestKey(g.path),
            title: convertPathToTitle(g.path),
            transitionTarget: `/kanji/${g.path}`,
            checkCleared: () =>
                localStorage.getItem(getKanjiTestSaveKey(g.path)) === "100",
            reward: { type: "coin", value: 20 },
        };
    });
}

export function convertPathToTitle(path: string) {
    return path
        .split("-")
        .map(p => (p === "jlpt" ? "JLPT" : capitalizeInitialChar(p)))
        .join(" ");
}

export const kanjiTestKeyInitial = "kanji-test-";
export function getKanjiTestQuestKey(path: string) {
    return `${kanjiTestKeyInitial}${path}`;
}
