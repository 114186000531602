import { sFetchJson } from "../../../../../../common/util/sFetchJson";
import { KanjiTestFromServerSide } from "../../../../zApps/KanjiTest/types";
import { CompareFunctions } from "./types";

export const getKanjiTestFnc = async () => {
    const allKanjiTest = await sFetchAllKanjiTest();
    return getFunctionsFromAllKanjiTest(allKanjiTest);
};

export async function sFetchAllKanjiTest(
    setNewKanjiTest?: (allKanjiTest: KanjiTestFromServerSide[]) => void
): Promise<KanjiTestFromServerSide[]> {
    return sFetchJson<KanjiTestFromServerSide[]>(
        "api/KanjiTest/GetAllKanjiTest",
        setNewKanjiTest
    );
}

export function getFunctionsFromAllKanjiTest(
    allKanjiTest: KanjiTestFromServerSide[]
) {
    return allKanjiTest.reduce((acc, kanjiTest) => {
        return {
            ...acc,
            [getKanjiTestSaveKey(kanjiTest.path)]: (
                db: string | null,
                storage: string | null
            ) => {
                const storageScore = Number(storage) || 0;
                const dbScore = Number(db) || 0;
                if (storageScore > dbScore) {
                    return "storage";
                }
                return "db";
            },
        };
    }, {} as CompareFunctions);
}

export function getKanjiTestSaveKey(path: string) {
    return `KanjiTest-score-${path}`;
}
